<template>
  <div class="limiter">
    <div class="container-login100" :style="{ backgroundImage: 'url(' + require('@/assets/img/bg-01.jpg') + ')' }">
      <div></div>
      <div style="margin-top: -200px">
        <div
            style="display:flex;justify-content:center;background-color: white;border-radius: 20px;padding: 16px 0;width: 240px;margin:20px auto;background-color: white">
          <img style="width: 160px;" src="../assets/img/login.png">
        </div>
        <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form class="login100-form validate-form">
            <div style="display: flex;justify-content: center;">
              <div @click="choiceLoginType(true)" style="width: 50%"
                   :style="thisLoginType == true ? 'background-color: #e2e2e2;' : 'background-color: white;color: #8e8d8d;'">
                <div style="padding-top: 20px;"
                     :style="thisLoginType == true ? 'background-color: white;border-radius: 0 20px 0 0;' : 'background-color: #e2e2e2;border-radius: 0 0 20px 0;'">
                  <h4 class="go">短信登录</h4>
                </div>
              </div>
              <div @click="choiceLoginType(false)" style="width: 50%;"
                   :style="thisLoginType == false ? 'background-image: linear-gradient(#e2e2e2 50%, white 50%);' : 'background-image: linear-gradient(#e2e2e2 50%,white 50%);color: #8e8d8d;'">
                <div style="padding-top: 20px;"
                     :style="thisLoginType == false ? 'background-color: white;border-radius:20px  0 0 20px' : 'background-color: #e2e2e2;border-radius: 0 0 0 20px'">
                  <h4 class="go">账户登录</h4>
                </div>
              </div>
            </div>
            <div v-if="thisLoginType == true" style="margin: 40px 50px;">
              <div style="margin: 20px 0" class="wrap-input100 validate-input m-b-23" data-validate="请输入用户名">
                <span style="margin-top: 10px" class="label-input100">手机号</span>
                <div style="display: flex;align-items:center;">
                  <div class="el-icon-mobile-phone" style="color: #8e8d8d;"></div>
                  <input maxlength="11" v-model="authCodeLogin.phone" class="input100" name="pass" placeholder="请输入手机号">
                  <span class="focus-input100"></span>
                </div>
              </div>
              <div class="wrap-input100 validate-input" data-validate="请输入验证码">
                <span class="label-input100">验证码</span>
                <div style="display: flex;align-items:center;">
                  <div class="el-icon-lock" style="color: #8e8d8d;"></div>
                  <input @keyup.enter="loginByAuthCode()" maxlength="6" v-model="authCodeLogin.code" class="input100"
                         name="pass" placeholder="请输入验证码">
                  <span class="focus-input100"></span>
                  <el-button :disabled="authCode != '获取验证码'" @click="getAuthCode()"
                             style="border-radius: 10px;padding:15px 15px"
                             size="small">
                    {{ authCode == '获取验证码' ? authCode : authCode + ' 秒' }}
                  </el-button>
                </div>

              </div>
              <div style="padding-top: 40px;text-align: center">
                <el-button style="margin: 0 auto;width: 200px;padding: 19px" :loading="isLogin" :disabled="isLogin"
                           @click="loginByAuthCode()" round>{{ isLogin == true ? '请稍等...' : '登 录' }}
                </el-button>
              </div>
            </div>
            <div v-else style="margin: 40px 50px;">
              <div style="margin: 20px 0" class="wrap-input100 validate-input m-b-23" data-validate="请输入用户名">
                <span style="margin-top: 10px" class="label-input100">账户</span>
                <div style="display: flex;align-items:center;">
                  <div class="el-icon-user" style="color: #8e8d8d;"></div>
                  <input v-model="param.username" class="input100" name="pass" placeholder="请输入密码">
                  <span class="focus-input100"></span>
                </div>
              </div>
              <div class="wrap-input100 validate-input" data-validate="请输入密码">
                <span class="label-input100">密码</span>
                <div style="display: flex;align-items:center;">
                  <div class="el-icon-lock" style="color: #8e8d8d;"></div>
                  <input @keyup.enter="loginByAccount()" v-model="param.password" class="input100" type="password"
                         name="pass" placeholder="请输入密码">
                  <span class="focus-input100"></span>
                </div>

              </div>
              <div style="padding-top: 40px;text-align: center">
                <el-button style="margin: 0 auto;width: 200px;padding: 19px" :loading="isLogin" :disabled="isLogin"
                           @click="loginByAccount()" round>{{ isLogin == true ? '请稍等...' : '登 录' }}
                </el-button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {getAuthCode, login, loginByAuthCode} from '../api/login'

export default {
  name: "NewLogin",
  data() {
    return {
      thisLoginType: true,
      authCode: "获取验证码",
      isLogin: false,
      authCodeLogin: {
        phone: "",
        code: ""
      },
      param: {
        username: "",
        password: ""
      },
    };
  },
  methods: {
    /**
     * 选择登陆方式
     */
    choiceLoginType(type) {
      this.thisLoginType = type;
    },
    /**
     * 获取验证码
     */
    getAuthCode() {
      let phone = this.authCodeLogin.phone;
      if (phone.length == 11 && /^[0-9]+.?[0-9]*/.test(phone)) {
        this.authCode = 60;
        getAuthCode(phone).then(res => {
          if (res.code == 200) {
            this.$message.success("已发送！");
          } else if (res.code == 500) {
            this.$message.warning(res.message);
          }
        })
      } else {
        this.$message.warning('手机号有误！');
      }
    },
    /**
     * 验证码登陆
     */
    loginByAuthCode() {
      let phone = this.authCodeLogin.phone;
      let code = this.authCodeLogin.code;
      if (phone.length != 11 && !/^[0-9]+.?[0-9]*/.test(phone)) {
        this.$message.warning('手机号有误！');
        return;
      }
      if (code.length != 6 && !/^[0-9]+.?[0-9]*/.test(code)) {
        this.$message.warning('验证码有误！');
        return;
      }
      this.isLogin = true;
      loginByAuthCode(this.authCodeLogin).then(res => {
        this.isLogin = false;
        if (res.code == 200) {
          this.$message.success("登陆成功！");
          localStorage.setItem("ms_username", res.data.username);
          console.log(res.data.username);
          localStorage.setItem("token", res.data.token);//token
          localStorage.setItem("coachId", res.data.coachId);//校区ID
          localStorage.setItem("region", res.data.regionId);//校区ID
          localStorage.setItem("imgUrl", res.data.imgUrl);//头像
          localStorage.setItem("roleId", res.data.roleId);//角色ID
          localStorage.setItem("menu", JSON.stringify(res.data.sysMenuList));//sys_user_menu表中(type=0菜单) 多级web端 页面 权限list
          localStorage.setItem("toolsMenu", JSON.stringify(res.data.sysToolsList));//sys_user_menu表中(type=1工具) 多级web端 工具 权限list
          localStorage.setItem("studentType", JSON.stringify(res.data.studentType));
          localStorage.setItem("sysUserId", res.data.id);//当前登陆用户的sys_user_id
          document.cookie= "token="+res.data.token;
          let menu = this.getTypeList(JSON.parse(localStorage.getItem('menu')))
          for (let i = 0; i < menu.length; i++) {
            if (menu[i].children) {
              this.$router.push({
                path: `/${menu[i].children[0].index}`
              })
              break
            } else {
              console.log(menu[i].index);
              this.$router.push({
                path: `/${menu[i].index}`
              })
              break
            }
          }
        } else if (res.code === 500) {
          this.$message.warning(res.message);
        }
      })
    },
    /**
     * 用户名密码登陆
     */
    loginByAccount() {
      let user = this.param.username;
      let password = this.param.password;
      if (user === "") {
        this.$message.warning('请输入账户！');
        return;
      }
      if (password === "") {
        this.$message.warning('请输入密码！');
        return;
      }
      this.isLogin = true;
      login(this.param).then(res => {
        this.isLogin = false;
        if (res.code === 200) {
          this.$message.success("登录成功 :" + this.param.username);
          localStorage.setItem("ms_username", this.param.username);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("region", res.data.regionId);
          localStorage.setItem("coachId", res.data.coachId);//校区ID
          localStorage.setItem("imgUrl", res.data.imgUrl);
          localStorage.setItem("roleId", res.data.roleId);
          localStorage.setItem("toolsMenu", JSON.stringify(res.data.sysToolsList));
          localStorage.setItem("studentType", JSON.stringify(res.data.studentType));
          document.cookie= "token="+res.data.token;
          //获取全部menu
          var allMenu = JSON.parse(JSON.stringify(res.data.sysMenuList));
          //将全部menu树状拼装
          allMenu = this.getTypeList2(allMenu);
          //设置全部menu到本地存储
          localStorage.setItem("allMenu", JSON.stringify(allMenu));

          //获取全部menu
          let menu = JSON.parse(JSON.stringify(res.data.sysMenuList));
          //将全部menu再次 树状拼装 此版本是 允许包含 按钮级别权限控制permission的
          menu = this.getTypeList(menu);
          //设置有按钮级别权限的进入permission进入menu
          localStorage.setItem("menu", JSON.stringify(menu));

          this.$message.success("登录成功,即将跳转 :" + this.param.username);
          // 进入第一个页面
          for (let i = 0; i < menu.length; i++) {
            try {
              if (menu[i].children) {
                console.log("开始执行if的登陆跳转" + menu[i].index)
                this.$router.push({
                  path: `/${menu[i].children[0].index}`
                })
                break
              } else {
                console.log("开始执行else登陆跳转" + menu[i].index)
                this.$router.push({
                  path: `/${menu[i].index}`
                })
                break
              }
            } catch (e) {
              console.log("获取首个权限时出错了,执行默认页面");
              this.$router.push({
                path: `/`
              })
              break;
            }
          }
        } else {
          this.$message.error(res.message);
        }
        //执行下return
        return;
      })
    },
    getTypeList(commodityType) {
      commodityType.forEach(items => {
        if (items != null) {
          //isButton是0 则不是按钮 不设置当前xx页面thisPage的permission参数
          if (items.children != null && items.children.length > 0 && items.children[0].isButton === 0) {
            this.getTypeList(items.children);
          } else {
            //isButton是1 设置当前xx页面thisPage的permission参数
            if (items.children != null && items.children.length > 0 && items.children[0].isButton === 1) {
              let myPermission = [];
              items.children.forEach(item => {
                myPermission.push(item.index);
              });
              //设置多级的当前xx页面thisPage的permission参数
              items.permission = myPermission;
            }
            items.children = null;
          }
        }
      });
      return commodityType;
    },
    //解析多节Menu菜单页面 权限 按钮
    getTypeList2(commodityType) {
      commodityType.forEach(items => {
        if (items != null) {
          if (items.children != null && items.children.length > 0) {
            this.getTypeList2(items.children);
          } else {
            items.children = null;
          }
        }
      });
      return commodityType;
    },
  },
  watch: {
    authCode(value) {
      if (value == 0) {
        this.authCode = "获取验证码";
        return;
      }
      if (value != "获取验证码") {
        setTimeout(() => {
          this.authCode--;
        }, 1000);
      }
    },
  }
}
</script>
<style scoped>
a {
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #666666;
}

/*---------------------------------------------*/
p {
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

.get-password {
  font-size: 12px;
  width: 120px;
  border: #a7a7a7 1px solid;
  color: #a7a7a7;
  padding: 6px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input:-moz-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}

textarea:-moz-placeholder {
  color: #adadad;
}

textarea::-moz-placeholder {
  color: #adadad;
}

textarea:-ms-input-placeholder {
  color: #adadad;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
}

.txt2 {
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
  text-transform: uppercase;
}

.bg1 {
  background-color: #3b5998
}

.bg2 {
  background-color: #1da1f2
}

.bg3 {
  background-color: #ea4335
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.go {
  padding-bottom: 20px;
  text-align: center;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 240px;
}

.wrap-login100 {
  width: 380px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  /*padding:40px  50px;*/
}


/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  font-family: 'Microsoft Yahei';
  font-size: 39px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}


/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
}

.label-input100 {
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  color: #333333;
  line-height: 1.5;
}

.input100 {
  font-family: 'Microsoft Yahei';
  font-size: 12px;
  color: #333333;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 50px;
  background: transparent;
  padding: 0 7px 0 20px;
}


/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::after {
  content: attr(data-symbol);
  font-family: Material-Design-Iconic-Font;
  color: #adadad;
  font-size: 22px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(100% - 20px);
  bottom: 0;
  left: 0;
  padding-left: 13px;
  padding-top: 3px;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: #7f7f7f;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}


.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.input100:focus + .focus-input100::after {
  color: #666666;
}

.has-val.input100 + .focus-input100::after {
  color: #666666;
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  bottom: calc((100% - 20px) / 2);
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  right: 2px;
  pointer-events: none;

  font-family: 'Microsoft Yahei';
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  bottom: calc((100% - 20px) / 2);
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  right: 8px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}


.login100-social-item {
  font-size: 25px;
  color: #fff;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 5px;
}

.login100-social-item:hover {
  color: #fff;
  background-color: #333333;
}
</style>
